import winston from 'winston';

const standardConsole = (showBaseProperties: boolean) => {
  const { combine, colorize, printf, timestamp } = winston.format;

  const myFormat = printf(({ level, message, timestamp, dataset, ...rest }) => {
    let format = `[${timestamp}]`;
    if (dataset !== 'default') {
      format += ` (${dataset})`;
    }
    format += ` ${level}: ${message}`;
    const { error, ...unpacked } = rest;
    if (error) {
      format += `\n${error.stack}`;
    }
    if (unpacked) {
      if (!showBaseProperties) {
        delete unpacked.base;
      }
      if (Object.keys(unpacked).length > 0) {
        format += `\n${JSON.stringify(unpacked, null, 2)}`;
      }
    }
    return format;
  });

  return new winston.transports.Console({
    format: combine(
      colorize({
        level: true,
        colors: {
          trace: 'white',
          debug: 'green',
          info: 'blue',
          warn: 'yellow',
          error: 'red',
          fatal: 'red',
        },
      }),
      timestamp({ format: 'HH:mm:ss' }),
      myFormat
    ),
  });
};

export default standardConsole;
