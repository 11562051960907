import winston from 'winston';

export enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

export interface LoggerRegistry {
  [key: string]: { logger: winston.Logger; options: LoggerOptions };
}

export type PlainObject = { [name: string]: any };
export interface LoggerOptions {
  level: LogLevel;
  metadata?: PlainObject;
  transports: Array<winston.transport>;
}

export interface Logger {
  trace: (message: string, metadata?: PlainObject) => void;
  debug: (message: string, metadata?: PlainObject) => void;
  info: (message: string, metadata?: PlainObject) => void;
  warn: (message: string, metadata?: PlainObject) => void;
  error: (
    message: string,
    error?: Error | null,
    metadata?: PlainObject
  ) => void;
  fatal: (
    message: string,
    error?: Error | null,
    metadata?: PlainObject
  ) => void;
  child: (childName: string, metadata?: PlainObject) => Logger;
  close: () => void;
}

export interface Apm {
  trackError: (error: Error) => void;
}
