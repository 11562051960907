import winston from 'winston';
import { createChild } from './';
import * as apm from './apm';
import { Logger, LoggerOptions, LogLevel, PlainObject } from './types';

const createLoggerInterface = (
  { logger, options }: { logger: winston.Logger; options: LoggerOptions },
  name: string
): Logger => {
  const loggerInterface: Logger = {
    trace: (message: string, metadata?: Object) => {
      logger.log(LogLevel.TRACE, message, {
        dataset: name,
        ...{
          base: options.metadata,
          ...metadata,
        },
      });
    },
    debug: (message: string, metadata?: Object) => {
      logger.log(LogLevel.DEBUG, message, {
        dataset: name,
        ...{
          base: options.metadata,
          ...metadata,
        },
      });
    },
    info: (message: string, metadata?: Object) => {
      logger.log(LogLevel.INFO, message, {
        dataset: name,
        ...{
          base: options.metadata,
          ...metadata,
        },
      });
    },
    warn: (message: string, metadata?: Object) => {
      logger.log(LogLevel.WARN, message, {
        dataset: name,
        ...{
          base: options.metadata,
          ...metadata,
        },
      });
    },
    error: (message: string, error?: Error | null, metadata?: Object) => {
      let fullMetadata: any = {
        dataset: name,
        base: options.metadata,
        ...metadata,
      };
      if (error) {
        apm.trackError(error);
        fullMetadata.error = {
          fatal: false,
          type: error.name,
          message: error.message,
          stack: error.stack,
        };
      }
      logger.log(LogLevel.ERROR, message, fullMetadata);
    },
    fatal: (message: string, error?: Error | null, metadata?: Object) => {
      let fullMetadata: any = {
        dataset: name,
        base: options.metadata,
        ...metadata,
      };
      if (error) {
        apm.trackError(error);
        fullMetadata.error = {
          fatal: true,
          type: error.name,
          message: error.message,
          stack: error.stack,
        };
      }
      logger.log(LogLevel.FATAL, message, fullMetadata);
    },
    child: (childName: string, metadata?: PlainObject): Logger => {
      return createChild(metadata, `${name}_${childName}`, name);
    },
    close: () => {
      logger.close();
    },
  };
  return loggerInterface;
};

export default createLoggerInterface;
